export default {
  methods: {
    /**
     * Sorts sections and items by `order` field. Returns a sorted new array, even if `elements` argument was object.
     */
    getSortedReportElements (elements) {
      const elementsArray = Array.isArray(elements)
        ? [].concat(elements)
        : [].concat(Object.values(elements));

      return elementsArray.sort((elementA, elementB) => {
        if (elementA.order !== undefined && elementA.order !== null && elementB.order !== undefined && elementB.order !== null) {
          return (elementA.order - elementB.order);
        }
        return 0;
      });
    },
  },
}
