<template>
  <ion-page>
    <ion-content ref="content" :fullscreen="true">
      <template v-if="!loading">
        <div class="ios hydrated item-header">
          <div class="item-header-container">
            <div class="orange-circle" @click="goToJob">
              <uil-angle-left size="20px" />
            </div>
            <div class="icon-container" style="margin-left: 10px;">
              <div class="white-circle">
                <component size="25px" :is="itemIcon" />
              </div>
            </div>
            <div class="item-title">
              <h1>{{ item.name }}</h1>
              <div class="item-section">- {{ section.name }}</div>
            </div>
            <div class="icon-container" style="opacity: 0;">
              <div class="white-circle">
                <component size="25px" :is="itemIcon" />
              </div>
            </div>
            <div class="orange-circle"  style="opacity: 0;">
              <uil-angle-left size="25px" />
            </div>
          </div>

          <template v-if="item.images && item.images.length">
            <ion-row class="image-row">
              <ion-col size="4" :key="photo" v-for="(photo, index) in item.images" class="image-col">
                <aspect-ratio :aspect-width="1" :aspect-height="1">
                  <indexed-db-img
                    :idb-key="photo.id"
                    @click="openLightboxImage(index, item.images.map(image => image.id))"
                    class="object-fit-cover"
                  />
                </aspect-ratio>
              </ion-col>
            </ion-row>
          </template>

          <template v-if="item.description">
            <div class="item-description">{{ item.description }}</div>
          </template>
        </div>

        <div class="question-section">
          <div class="section-title">
            <h2>Questions</h2>
          </div>

          <template v-if="filteredAndSortedQuestions.length">
            <ion-card v-for="question in filteredAndSortedQuestions" :key="question.id" class="question-card">
              <template v-if="question">
                <div class="card-labels" v-if="question.required == 1 || question.required == 0">
                  <div v-if="question.required == 1" class="card-label">Required</div>
                  <div v-else-if="question.required == 0" class="card-label">Recommended</div>
                </div>
                <!-- ... and 'Optional' is `question.required == 2` for reasons -->
                <ion-card-content>
                  <div class="question-title">{{ question.title }}</div>
                  <template v-if="question.description">
                    <div class="question-description">{{ question.description }}</div>
                  </template>

                  <template v-if="imgsQuestion[question.id] && imgsQuestion[question.id].length">
                    <div class="question-images">
                      <ion-row class="image-row">
                        <ion-col size="4" v-for="(image, index) in imgsQuestion[question.id]" :key="image.id" class="image-col">
                          <aspect-ratio :aspect-width="1" :aspect-height="1">
                            <indexed-db-img
                              :idb-key="image.id"
                              @click="openLightboxImage(index, imgsQuestion[question.id].map(image => image.id))"
                              class="object-fit-cover"
                            />
                          </aspect-ratio>
                        </ion-col>
                      </ion-row>
                    </div>
                  </template>

                  <template v-if="question.tags && question.tags.length && question.tags[0]">
                    <div class="question-tag-container">
                      <div class="question-tag" v-for="tag in question.tags" :key="tag">
                        {{ tag }}
                      </div>
                    </div>
                  </template>

                  <!-- TODO - answer section -->
                  <div class="answer-container">
                    <div class="answer-card">
                      <template v-if="question.type == 'text'">
                        <ion-input @ionBlur="save(question)" inputmode="text" type="text" placeholder="Type your answer" v-model="answersInput[question.id]"  clearInput="true" />
                      </template>
                      <template v-else-if="question.type == 'multiline_text' || question.type == 'multiline'">
                        <ion-textarea @ionBlur="save(question)" rows="5" placeholder="Type your answer" v-model="answersInput[question.id]" />
                      </template>
                      <template v-else-if="question.type == 'image'">
                        <!-- open camera -->
                        <div class="image-container" v-if="showImage[question.id]">
                          <indexed-db-img
                            :idb-key="getImageFileIdFromQuestion(question)"
                            @click="openLightboxImage(0, [getImageFileIdFromQuestion(question)])"
                          />
                          <div class="bin" @click="clearAnswer(question)"><uil-trash-alt size="25px" /></div>
                        </div>
                        <div class="add-media-container" @click="takePicture(question, 'question')">
                          <uil-camera class="other" size="25px" />
                          <div class="other">Add Main Photo</div>
                        </div>
                      </template>
                      <template v-else-if="question.type == 'video'">
                        <div class="video-container">
                          <template v-if="answersInput[question.id]">
                            <video id="playback" controls :src="answersInput[question.id]" />
                          </template>
                          <Video @emitVideoData="videoRun" :question="question" />
                        </div>
                      </template>
                      <template v-else-if="question.type == 'signature'">
                        <div class="image-container" v-if="getImageFileIdFromQuestion(question)">
                          <indexed-db-img
                            :idb-key="getImageFileIdFromQuestion(question)"
                            @click="openLightboxImage(0, [getImageFileIdFromQuestion(question)])"
                          />
                          <div class="bin" @click="clearAnswer(question)"><uil-trash-alt size="25px" /></div>
                        </div>
                        <ion-button class="sig-btn" @click="openSig(question)">Open Signature</ion-button>
                        <div v-show="showSigs[question.id]">
                          <VueSignaturePad  class="border" width="100%" height="100px" :ref="'signaturePad'+question.id" />
                          <div class="save-container">
                            <ion-button class="sig-btn" @click="saveSig(question)">Save</ion-button>
                            <ion-button class="sig-btn" @click="clearSig(question)">Clear</ion-button>
                            <div class="message">Pressing save will overwrite the current signature</div>
                          </div>
                        </div>
                      </template>
                      <template v-else-if="question.type == 'yesno' || question.type == 'checkbox'">
                        <ion-toggle v-model="answersInput[question.id]" @ionChange="save(question)" :check="answersInput[question.id]" color="primary" />
                      </template>
                      <template v-else-if="question.type == 'number'">
                        <ion-input @ionBlur="save(question)" inputmode="numeric" type="number" step="1" placeholder="Type your answer" v-model="answersInput[question.id]"
                                   @keypress="numberOnlyValidation($event)"
                                   pattern="[0-9.]*"
                        />
                      </template>
                      <template v-else-if="question.type == 'decimal'">
                        <ion-input @ionBlur="save(question)" inputmode="decimal" type="number" step="0.1" placeholder="Type your answer" v-model="answersInput[question.id]" />
                      </template>
                      <template v-else-if="question.type == 'single_choice'">
                        <ion-select @ionChange="save(question)" ok-text="Select" cancel-text="Cancel" placeholder="Choose an answer" v-model="answersInput[question.id]">
                          <ion-select-option v-for="choice in question.choices" :key="choice" :value="choice">{{ choice }}</ion-select-option>
                        </ion-select>
                      </template>
                      <template v-else-if="question.type == 'multiple_choice'">
                        <ion-select multiple="true" @ionChange="save(question)" ok-text="Select" cancel-text="Cancel" placeholder="Choose an answer" v-model="answersInput[question.id]">
                          <ion-select-option v-for="choice in question.choices" :key="choice" :value="choice">{{ choice }}</ion-select-option>
                        </ion-select>
                      </template>
                      <template v-else-if="question.type == 'rag'">
                        <ion-radio-group @ionChange="save(question)" v-model="answersInput[question.id]">
                          <ion-item lines="none" class="red">
                            <ion-label>Red</ion-label>
                            <ion-radio mode="md" slot="start" value="red" />
                          </ion-item>

                          <ion-item lines="none" class="amber">
                            <ion-label>Amber</ion-label>
                            <ion-radio mode="md" slot="start" value="amber" />
                          </ion-item>

                          <ion-item lines="none" class="green">
                            <ion-label>Green</ion-label>
                            <ion-radio mode="md" slot="start" value="green" />
                          </ion-item>
                        </ion-radio-group>
                      </template>
                      <template v-else-if="question.type == 'slider'">
                        <ion-range debounce="500" @ionChange="save(question)" v-model="answersInput[question.id]" ticks="false" :min="question.choices.range_minimum" :max="question.choices.range_maximum" snaps="false" color="secondary">
                          <div slot="start">{{ question.choices.range_minimum }}</div>
                          <div slot="end">{{ question.choices.range_maximum }}</div>
                        </ion-range>
                        <div>Value: <span>{{ answersInput[question.id] }}</span></div>
                      </template>
                      <template v-else-if="question.type == 'stepper'">
                        <ion-range :step="question.choices.range_increment" debounce="500" @ionChange="save(question)" v-model="answersInput[question.id]" ticks="true" :min="question.choices.range_minimum" :max="question.choices.range_maximum" snaps="true" color="secondary">
                          <div slot="start">{{ question.choices.range_minimum }}</div>
                          <div slot="end">{{ question.choices.range_maximum }}</div>
                        </ion-range>

                        <div>Value: <span>{{ answersInput[question.id] }}</span></div>
                      </template>
                      <template v-else-if="question.type == 'star'">
                        <div class="star-rating">
                          <template v-for="x in parseInt(question.choices.range_maximum)" :key="x">
                            <template v-if="x <= answersInput[question.id]">
                              <uis-star style="color: #db9123;" @click="starSelect(x, question)" size="25px" />
                            </template>
                            <template v-else>
                              <uis-star style="color: #cbcaca;" @click="starSelect(x, question)" size="25px" />
                            </template>
                          </template>
                          <ion-input type="hidden" v-model="answersInput[question.id]" />
                        </div>
                      </template>
                    </div>
                  </div>

                  <!-- notes section -->
                  <template v-if="question.notes == '1' || question.notes == 1">
                    <div class="notes-container">
                      <div class="notes-title" @click="toggleNotesAccordion(question)">
                        <div>
                          <span>Notes</span>
                          <template v-if="(cameraPhotos[question.id] && cameraPhotos[question.id].length) || (answerNoteInput[question.id])">
                            <uis-check-circle size="20px" class="section-tick" />
                          </template>
                        </div>

                        <template v-if="notesAccordion[question.id]">
                          <uil-angle-up size="25px" />
                        </template>
                        <template v-else>
                          <uil-angle-down size="25px" />
                        </template>
                      </div>
                      <ion-card class="notes-card" v-show="notesAccordion[question.id]">
                        <ion-card-content>
                          <ion-textarea @ionBlur="save(question)" rows="5" placeholder="Enter answer notes here..." v-model="answerNoteInput[question.id]" />

                          <div class="add-media-container" @click="takePicture(question, 'notes')">
                            <div class="orange-circle">
                              <uil-plus size="35px" />
                            </div>
                            <uil-camera class="other" size="25px" />
                            <div class="other">Add Notes Photo</div>
                          </div>
                          <ion-grid class="taken-photos">
                            <ion-row>
                              <ion-col size="6" :key="photo" v-for="(photo, index) in cameraPhotos[question.id]">
                                <div class="notes-image-container">
                                  <aspect-ratio :aspect-width="4" :aspect-height="3">
                                    <indexed-db-img
                                      :idb-key="photo"
                                      @click="openLightboxImage(index, cameraPhotos[question.id])"
                                      class="object-fit-cover"
                                    />
                                  </aspect-ratio>
                                  <div v-if="this.asset.id == 96" class="notes-image-container-label">
                                    {{ (index + 1) }}.
                                  </div>
                                  <div class="bin" @click="clearNoteImage(question, index)"><uil-trash-alt size="25px" /></div>
                                  <form
                                    v-if="this.asset.id == 96"
                                    class="notes-image-container-duplicator-form"
                                    @submit="handleImageDuplicatorSubmit($event, question, photo)"
                                  >
                                    <input type="number" value="0">
                                    <button type="submit">Duplicate</button>
                                  </form>
                                </div>
                              </ion-col>
                            </ion-row>
                          </ion-grid>
                        </ion-card-content>
                      </ion-card>
                    </div>
                  </template>
                </ion-card-content>
              </template>
            </ion-card>
          </template>
          <template v-else>
            <div class="no-message">There are no questions for this item.</div>
          </template>
        </div>
      </template>

      <indexed-db-lightbox
        v-model:visible="lightbox.visible"
        v-model:index="lightbox.index"
        :idb-keys="lightbox.idbKeys"
      />
    </ion-content>

    <ion-footer>
      <ion-toolbar>
        <ion-tabs>
          <ion-router-outlet />
          <ion-tab-bar slot="bottom">
            <ion-tab-button tab="additem" color="primary" @click="previousOrNextItem('previous')">
              <uil-angle-left-b size="25px" />
              <ion-label>Prev</ion-label>
            </ion-tab-button>
            <ion-tab-button tab="notes" @click="goToJob">
              <uil-list-ul  size="25px" />
              <ion-label>Items</ion-label>
            </ion-tab-button>
            <ion-tab-button tab="send" @click="previousOrNextItem('next')">
              <uil-angle-right-b size="25px" />
              <ion-label>Next</ion-label>
            </ion-tab-button>
          </ion-tab-bar>
        </ion-tabs>
      </ion-toolbar>
    </ion-footer>
  </ion-page>
</template>

<script>
import {
  IonButton,
  IonCard,
  IonCardContent,
  IonCol,
  IonContent,
  IonFooter,
  IonGrid,
  IonInput,
  IonItem,
  IonLabel,
  IonPage,
  IonProgressBar,
  IonRadio,
  IonRadioGroup,
  IonRange,
  IonRouterOutlet,
  IonRow,
  IonSelect,
  IonSelectOption,
  IonTabBar,
  IonTabButton,
  IonTabs,
  IonTextarea,
  IonToggle,
  IonToolbar,
} from '@ionic/vue';
import {mapGetters} from 'vuex';
import {
  UilAngleDown,
  UilAngleLeft,
  UilAngleLeftB,
  UilAngleRightB,
  UilAngleUp,
  UilCamera,
  UilListUl,
  UilPlus,
  UilSearchPlus,
  UilTrashAlt,
  UilVideo,
} from '@iconscout/vue-unicons'
import {UisCheckCircle, UisStar} from '@iconscout/vue-unicons-solid'
import {defineAsyncComponent} from "vue";
import {Camera, CameraDirection, CameraResultType, CameraSource} from "@capacitor/camera";
import Video from '@/components/Video';
import imageCompression from 'browser-image-compression';
import helpers from '@/helpers/data-handling';
import imageFunctions from '@/helpers/localforage_functions';
import IndexedDbImg from "@/components/IndexedDbImg";
import storeMixin from "@/store/component-mixin";
import mutatesJobsMixin from "@/mixins/mutates-jobs";
import loadsData from "@/mixins/loads-data";
import IndexedDbLightbox from "@/components/IndexedDbLightbox.vue";
import AspectRatio from "@/components/AspectRatio.vue"; // Loading the src component so we get non-minified error messages

export default {
  mixins: [
    storeMixin,
    mutatesJobsMixin,
    loadsData,
  ],
  components: {
    AspectRatio,
    IndexedDbLightbox,
    IndexedDbImg,
    IonButton,
    IonCard,
    IonCardContent,
    IonCol,
    IonContent,
    IonFooter,
    IonGrid,
    IonInput,
    IonItem,
    IonLabel,
    IonPage,
    IonProgressBar,
    IonRadio,
    IonRadioGroup,
    IonRange,
    IonRouterOutlet,
    IonRow,
    IonSelect,
    IonSelectOption,
    IonTabBar,
    IonTabButton,
    IonTabs,
    IonTextarea,
    IonToggle,
    IonToolbar,
    UilAngleDown,
    UilAngleLeft,
    UilAngleLeftB,
    UilAngleRightB,
    UilAngleUp,
    UilCamera,
    UilListUl,
    UilPlus,
    UilSearchPlus,
    UilTrashAlt,
    UilVideo,
    UisCheckCircle,
    UisStar,
    Video,
  },
  data () {
    return {
      loading: true,
      answerNoteInput: {},
      answersInput: {},
      notesAccordion: {},
      showSigs: {},
      showImage: {},
      activeQuestion: null,
      cameraPhotos: {},
      imgsQuestion: {},
      lightbox: {
        visible: false,
        idbKeys: [],
        index: 0,
      },
    }
  },
  computed: {
    ...mapGetters({
      user: 'auth/user',
    }),
    jobId () {
      return this.$route.params.id;
    },
    sectionId () {
      return this.$route.params.section;
    },
    itemId () {
      return this.$route.params.item;
    },
    itemIcon () {
      let icon = this.item.icon_import;
      icon = icon.replace(/(\r\n|\n|\r)/gm, "").replace(/\s/g, "");
      return defineAsyncComponent(() => import('@iconscout/vue-unicons/icons/'+icon + ''))
    },
    filteredAndSortedQuestions () {
      return !this.item ? [] : this.getSortedReportElements(this.item.questions)
        .filter((question) => {
          if (question.conditional && Array.isArray(question.conditional) && question.conditional.length === 3) {
            const conditionTargetQuestionId = question.conditional[0],
              conditionTargetQuestionAnswer = this.answersInput[conditionTargetQuestionId],
              conditionOperator = question.conditional[1],
              conditionOperand = question.conditional[2];

            switch (conditionOperator) {
              case "=":
                return (
                  ((typeof conditionTargetQuestionAnswer === "boolean") && conditionTargetQuestionAnswer && conditionOperand === 'yes')
                  || ((typeof conditionTargetQuestionAnswer === "boolean") && !conditionTargetQuestionAnswer && conditionOperand === 'no')
                  || (conditionTargetQuestionAnswer == conditionOperand)
                );
              case "!=":
                return (
                  ((typeof conditionTargetQuestionAnswer === "boolean") && conditionTargetQuestionAnswer && conditionOperand === 'no')
                  || ((typeof conditionTargetQuestionAnswer === "boolean") && !conditionTargetQuestionAnswer && conditionOperand === 'yes')
                  || (conditionTargetQuestionAnswer == conditionOperand)
                );
              case "<":
                return (conditionTargetQuestionAnswer < conditionOperand);
              case ">":
                return (conditionTargetQuestionAnswer > conditionOperand);
              default:
                return false;
            }
          }
          return true;
        });
    },
  },
  watch: {
    'item.id': {
      immediate: true,
      handler (newValue) {
        console.log("Item.watch.'item.id'.handler()", newValue);
        this.scrollToTop()
        this.loading = true;
        this.imgsQuestion = {};

        // load already prefilled data in
        if (!this.item) {
          return null;
        }
        for (let question_prop in this.item.questions) {
          let current = this.item.questions[question_prop];
          this.cameraPhotos[current.id] = [];
          this.answersInput[current.id] = null;
          // TODO - load all other questions types
          if ((current.type === "yesno" || current.type === "checkbox") && current.answer.answer == null) {
            this.answersInput[current.id] = false;
          } else if (current.type === "video" && current.answer.answer) {
            let video;
            if (current.answer.answer.base64) {
              video = current.answer.answer.base64;
            } else {
              video = current.answer.answer;
            }

            video = video.replace(",opus", "")
            this.answersInput[current.id] = video;
          } else if (current.type === "signature" || current.type === "image") {
            this.answersInput[current.id] = current.answer.answer;
            this.showImage[current.id] = true;
          } else if (current.answer.answer) {
            this.answersInput[current.id] = current.answer.answer;
            this.showImage[current.id] = false;
          }

          // answer note fields
          if (current.answer_note.text) {
            this.answerNoteInput[current.id] = current.answer_note.text;
          }

          if (current.answer_note.image && current.answer_note.image.length > 0) {
            for (let y = 0; y < current.answer_note.image.length; y++) {
              if (current.answer_note.image[y].id) {
                this.cameraPhotos[current.id].push(current.answer_note.image[y].id)
              } else {
                this.cameraPhotos[current.id].push(current.answer_note.image[y])
              }
            }
          }

          this.imgsQuestion[current.id] = (current.images && current.images.length) ? current.images : [];
        }

        this.loading = false;
      }
    },
  },
  methods: {
    scrollToTop () {
      if (this.$refs.content) {
        this.$refs.content.$el.scrollToTop()
      }
    },
    getNewItemIndex (id, type) {
      let keys = Object.keys(this.items);
      let index = keys.indexOf(id.toString());
      let length = keys.length;
      if (index > -1) {
        if (type === "previous") {
          if (index == 0) {
            index = length - 1;
          } else {
            index--;
          }
        } else {
          // next
          if (index === (length - 1)) {
            index = 0;
          } else {
            index++;
          }
        }
        return this.items[keys[index]];
      } else {
        return false;
      }
    },
    goToJob () {
      this.$router.push({ path: `/job/${this.job.id}` })
    },
    async previousOrNextItem (type) {
      await this.loadingTriggered();
      const newItem = this.getNewItemIndex(this.item.id, type);
      if (newItem) {
        await this.$router.push({path: `/job/${this.job.id}/section/${this.section.id}/item/${newItem.id}`})
      }
      await this.closeModal();
    },
    save (question) {
      console.log('Item.methods.save()');
      const itemQuestion = this.item.questions[question.id];
      itemQuestion.answer_note.text = this.answerNoteInput[question.id];

      if (this.cameraPhotos[question.id] && this.cameraPhotos[question.id].length) {
        itemQuestion.answer_note.image = [];
        itemQuestion.answer_note.image = this.cameraPhotos[question.id];
      }

      /* WARNING: Only mutate itemQuestion if there are actual answer changes. As any changes will be synced to Vuex */
      // We compare JSON representations because changes like JS types (e.g. `NaN` to `null`) trigger a new `date_answered`, which may not be what the user intended.
      if (JSON.stringify(itemQuestion.answer.answer) !== JSON.stringify(this.answersInput[question.id])) {
        itemQuestion.answer.answer = this.answersInput[question.id];
        itemQuestion.answer.date_answered = new Date().getTime();
        itemQuestion.answer.answered_by = this.user.id;
      }
    },
    async videoRun (data,question) {
      question.answer.answer = data;
      this.saveVideo(question)
    },
    saveVideo (question) {
      let video = question.answer.answer;
      if (video) {
        video = video.replace(",opus", "")
      }
      this.answersInput[question.id] = video;
      this.save(question);
    },
    toggleNotesAccordion (question) {
      this.notesAccordion[question.id] = !this.notesAccordion[question.id];
    },
    async starSelect (index, question) {
      this.answersInput[question.id] = index;
      this.save(question)
    },
    openSig (question) {
      this.showSigs[question.id] = true;
      this.$nextTick(() => {
        this.$refs['signaturePad' + question.id].resizeCanvas();
      });
    },
    clearIfImage (toCheck) {
      imageFunctions.removeImage(toCheck);
    },
    async saveSig (question) {
      let vm = this;
      const { data } = this.$refs['signaturePad' + question.id].saveSignature();
      if (data) {
        helpers.lowestImageID().then(function (newID) {
          let savedFileImage = { "base64": data, "name": 'signaturePad' + question.id, "job_id": vm.job.id, "id": newID }

          if (question.answer.answer) {
            vm.clearIfImage(question.answer.answer)
          }

          imageFunctions.setImage(savedFileImage, newID).then(() => {
            question.answer.answer = newID.toString();
            vm.showImage[question.id] = true;
            vm.answersInput[question.id] = newID.toString();
            vm.save(question)
          });
        });
      }
    },
    clearAnswer (question) {
      this.clearIfImage(question.answer.answer);
      question.answer.answer = null
      this.answersInput[question.id] = "";
      this.showImage[question.id] = false;
    },
    clearSig (question) {
      this.$refs['signaturePad' + question.id].clearSignature();
      this.clearIfImage(question.answer.answer)
    },
    async compressFileAndGetBase64 (imageFile, question, type) {
      let vm = this;
      const options = {
        maxSizeMB: 0.2,
        maxWidthOrHeight: 1920,
        useWebWorker: true
      }
      const compressedFile = await imageCompression(imageFile, options);

      const reader = new FileReader();
      reader.readAsDataURL(compressedFile);

      reader.onloadend = () => {
        let base64 = reader.result;
        const fileName = new Date().getTime() + '.jpeg';
        helpers.lowestImageID().then(function (newID) {
          const savedFileImage = {
            "name": fileName,
            "base64": base64,
            "job_id": vm.job.id,
            "id": newID
          };

          if (type === "notes") {

          } else if (type === "question") {
            if (question.answer.answer) {
              vm.clearIfImage(question.answer.answer)
            }
          }

          imageFunctions.setImage(savedFileImage, newID.toString())

          if (type === "notes") {
            vm.cameraPhotos[question.id].push(newID.toString());
          } else if (type === "question") {
            vm.answersInput[question.id] = newID.toString();
            vm.showImage[question.id] = true;
          }
          vm.save(question)
        })
      };
    },
    async takePicture (question, type) {
      this.activeQuestion = question;

      try {
        const image = await Camera.getPhoto({
          resultType: CameraResultType.Base64,
          source: CameraSource.Photos,
          quality: 10,
          allowEditing: true,
          correctOrientation: true,
          height: 1080,
          width: 1920,
          saveToGallery: true,
          direction: CameraDirection.Rear
        })

        let base64 = "data:image/jpeg;base64," + image.base64String;
        fetch(base64)
          .then(res => res.blob())
          .then(blob => {
            const file = new File([blob], "image",{ type: "image/jpeg" })
            this.compressFileAndGetBase64(file, question, type)
          })
      } catch (e) {
        console.log("error", e);
      }
    },
    getImageFileIdFromQuestion (question) {
      let id = 0;
      if (question.answer.answer && question.answer.answer.id) {
        id = question.answer.answer.id.toString();
      } else {
        id = (question.answer.answer === undefined || question.answer.answer === null)
          ? ""
          : question.answer.answer.toString();
      }
      return id;
    },
    async clearNoteImage (question, index) {
      this.clearIfImage(this.cameraPhotos[question.id][index]);
      this.cameraPhotos[question.id].splice(index, 1);
      question.answer_note.image.splice(index, 1)
      this.save(question)
    },
    numberOnlyValidation ($event) {
      if ($event.charCode === 0 || /\d/.test(String.fromCharCode($event.charCode))) {
        return true
      } else {
        $event.preventDefault();
      }
    },
    async handleImageDuplicatorSubmit ($event, question, photoId) {
      $event.preventDefault();
      const duplicatesRequired = parseInt($event.target[0].value);
      if (duplicatesRequired && duplicatesRequired > 0) {
        console.log("Item.methods.handleImageDuplicatorSubmit()", duplicatesRequired, question);
        for (let i = 1; i <= duplicatesRequired; i++) {
          const newPhotoId = (await this.duplicateIndexedDbImage(photoId)).toString();
          this.cameraPhotos[question.id].push(newPhotoId);
        }
        this.save(question);
      }
    },
    async duplicateIndexedDbImage (photoId) {
      const oldImage = await imageFunctions.getImage(photoId),
        newImageId = await helpers.lowestImageID();

      await imageFunctions.setImage(oldImage, newImageId);
      return newImageId;
    },
    openLightboxImage (imageIndex, imageIdbKeys) {
      this.lightbox.visible = true;
      this.lightbox.idbKeys = imageIdbKeys;
      this.lightbox.index = imageIndex;
    },
  },
}
</script>

<style scoped lang="scss">
h1,
h2 {
  text-align: center;
  margin-top: 0;
  font-size: 20px;
  font-weight: 800;
  line-height: 30px;
  color: #373636;
}

.item-header {
  margin-top: 80px;
  padding: 0 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  .item-header-container {
    display: flex;
    width: 100%;
    justify-content: space-between;
    h1 {
      margin-bottom: 0;
    }
    .item-section {
      font-family: "Raleway", sans-serif;
      font-size: 14px;
      font-weight: 800;
      text-align: center;
      color: #373636;
    }

    .icon-container {
      .white-circle {
        background-color: #ffffff;
        padding: 5px;
        height: 50px;
        width: 50px;
        box-shadow: rgb(0 0 0 / 12%) 0 4px 16px;
        border-radius: 50%;
        display: flex;
        justify-content: center;
        align-items: center;
        color: #db9123;
      }
    }
    .orange-circle {
      color: black;
    }
  }
  .item-description {
    padding: 5px 20px 0 20px;
    color: #373636;
    font-size: 14px;
    font-weight: normal;
    text-align: center;
    margin-top: 20px;
  }
}

.question-section {
  padding: 0 20px;
  .section-title {
    margin-top: 20px;
  }

  .question-card {
    border-radius: 0;
    margin-bottom: 20px;
    --background: #ffffff;
    position: relative;

    .card-labels {
      display: flex;
      justify-content: end;
    }

    .card-label {
      background-color: #db9123;
      padding: 10px;
      border-bottom-left-radius: 15px;
      width: fit-content;
      color: #373636;
      font-size: 12px;
      font-weight: 800;
      text-transform: uppercase;
      font-family: "Raleway", sans-serif;
    }

    .question-title {
      font-size: 14px;
      font-weight: 800;
      line-height: 16px;
      text-transform: uppercase;
      font-family: "Raleway", sans-serif;
      margin-bottom: 20px;
    }
    .question-description {
      margin-top: 10px;
      margin-bottom: 10px;
      font-size: 14px;
      font-weight: 800;
    }
    .question-images {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      justify-content: flex-start;
      align-content: stretch;
      align-items: center;

      img {
        width: 30%;
      }
    }
    .question-tag-container {
      display: flex;
      margin: 10px 0;
      .question-tag {
        background-color: #cbcaca;
        border-radius: 50px;
        width: -webkit-fit-content;
        width: -moz-fit-content;
        width: fit-content;
        padding: 0 8px;
        margin: 5px;
        color: #373636;
        line-height: 21px;
        font-size: 12px;
        font-weight: 600;
        &:first-child {
          margin-left: 0;
        }
        &:last-child {
          margin-right: 0;
        }
      }
    }
    .notes-container {
      .notes-title {
        font-size: 14px;
        font-weight: 800;
        font-family: "Raleway", sans-serif;
        display: flex;
        justify-content: space-between;
        align-items: center;

        div {
          display: flex;
          justify-content: space-between;
          align-items: center;
          span {
            margin-right: 5px;
          }
        }
      }

      .notes-card {
        border-radius: 0;
        box-shadow: rgb(0 0 0 / 12%) 0 4px 16px;
        margin: 0 0;
        background-color: #ffffff;
        margin-top: 10px;
        font-size: 14px;
        font-weight: 400;
        ion-card-content {
          padding: 10px;
        }
        .answer-note {
          font-size: 14px;
          font-weight: 400;
        }
        .btn-container {
          text-align: right;
          margin-top: 5px;
        }
        ion-button {
          --padding-bottom: 3px;
          --padding-top: 3px;
          --padding-start: 5px;
          --padding-end: 5px;
          height: 30px;
        }
        ion-textarea {
          font-size: 12px;
        }
      }
    }
    .answer-container {
      margin-bottom: 10px;
      font-size: 12px;
      .answer-card {
        margin: 0 0;
        margin-top: 10px;
        font-size: 14px;
        font-weight: 400;
        ion-card-content {
          padding: 10px;
        }
        ion-input,
        ion-textarea {
          font-size: 12px;
        }
        ion-select {
          background: #f5f5f5;
        }

        .red {
          --background: #ffffff;
          ion-label {
            --color: #b41f1f;
          }
          ion-radio {
            --color: #b41f1f;
            --color-checked: #b41f1f;
          }
        }
        .amber {
          --background: #ffffff;
          ion-label {
            --color: #f7b900;
          }
          ion-radio {
            --color: #f7b900;
            --color-checked: #f7b900;
          }
        }
        .green {
          --background: #ffffff;
          ion-label {
            --color: #1d9f67;
          }
          ion-radio {
            --color: #1d9f67;
            --color-checked: #1d9f67;
          }
        }
        .star-rating {
          svg {
            color: #cbcaca;
          }
        }
        .sig-btn {
          --padding-start: 5px;
          --padding-end: 5px;
          height: 30px;
          margin-left: 0;
        }
        .border {
          border: 1px dashed #a5a5a5;
        }
        .image-container {
          position: relative;
          margin-bottom: 5px;
          .bin {
            position: absolute;
            top: 0;
            right: 0;
            color: #db9123;
            background-color: #ffffff;
          }
        }
        .save-container {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          margin-top: 5px;
          ion-button {
            margin-right: 10px;
            margin-top: 0;
          }
          .message {
            font-size: 10px;
          }
        }
      }
    }
    .add-media-container {
      display: flex;
      justify-content: flex-start;
      align-items: center;
      margin-top: 10px;
      > * {
        margin-right: 10px;
      }
      .orange-circle {
        padding: 3px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
      .other {
        color: #373636;
        font-size: 14px;
        font-weight: bold;
      }
    }
  }
}

ion-toolbar {
  --min-height: 50px;
}

ion-tab-button {
  svg {
    color: #db9123;
  }
  --background: #172943;
  color: #ffffff;
}

.no-message {
  text-align: center;
  margin-top: 10px;
  padding: 0 10px;
}

ion-textarea {
  --background: #f5f5f5;
  --padding-start: 5px;
  --padding-end: 5px;
  --padding-top: 5px;
  --padding-bottom: 5px;
}

ion-input {
  --background: #f5f5f5;
  --padding-start: 5px;
  --padding-end: 5px;
  --padding-top: 5px;
  --padding-bottom: 5px;
}

.video-container {
  video {
    width: 100%;
    margin-bottom: 20px;
  }
  #controls {
    display: flex;
    button {
      background-color: #db9123;
      padding: 10px 20px;
      text-transform: uppercase;
      font-size: 14px;
      font-weight: 800;
      line-height: 16px;
      margin-right: 5px;
      &#stop {
        background-color: #172943;
      }
    }
  }
}

.notes-image-container {
  position: relative;
  .bin {
    position: absolute;
    top: 0;
    right: 0;
    color: #db9123;
    background-color: #ffffff;
  }
  .notes-image-container-label {
    position: absolute;
    top: 0;
    left: 0;
    font-size: 10px;
    font-weight: 800;
    text-transform: uppercase;
    color: #ffffff;
    background-color: #172943;
    padding: .5em .75em;
    border-bottom-right-radius: .75em;
  }
}

.taken-photos .image-container {
  position: relative;
  width: 100px;
  height: 100px;
  margin: auto;
  .magnifier {
    position: absolute;
    bottom: 0;
    right: 0;
    color: #db9123;
    background-color: #172943c4;
    padding: 2px;
    border-top-left-radius: 5px;
  }
}
.image-row {
  width: 100%;
}

#stop {
  color: #ffffff;
}

.section-tick {
  fill: #1d9f67;
  width: 20px;
  height: 20px;
  background-color: white;
  border-radius: 50%;
}

.notes-image-container-duplicator-form {
  display: flex;
  width: 100%;

  input {
    flex-grow: 1;
    max-width: 102px;
  }

  button {
    flex-shrink: 0;
  }
}
</style>
