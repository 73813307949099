<template>
  <div class="idb-lightbox" v-if="visible" @click="$emit('update:visible', false)">
    <div class="idb-lightbox__close" @click.stop.prevent="$emit('update:visible', false)">
      <slot name="icon-close">&times;</slot>
    </div>

    <div class="idb-lightbox__element" @click.stop="">
      <div
        class="idb-lightbox__arrow idb-lightbox__arrow--left"
        @click.stop.prevent="previous"
        :class="{ 'idb-lightbox__arrow--invisible': !hasPrevious }"
      >
        <slot name="action-previous">
          <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path d="M15.41 16.09l-4.58-4.59 4.58-4.59L14 5.5l-6 6 6 6z" />
            <path d="M0-.5h24v24H0z" fill="none" />
          </svg>
        </slot>
      </div>
      <div class="idb-lightbox__image" @click.stop="">
        <slot name="content" :idb-key="idbKey">
          <indexed-db-img :idb-key="idbKey" />
        </slot>
      </div>
      <div
        class="idb-lightbox__arrow idb-lightbox__arrow--right"
        @click.stop.prevent="next"
        :class="{ 'idb-lightbox__arrow--invisible': !hasNext }"
      >
        <slot name="action-next">
          <svg height="24" viewBox="0 0 24 24" width="24" xmlns="http://www.w3.org/2000/svg">
            <path d="M8.59 16.34l4.58-4.59-4.58-4.59L10 5.75l6 6-6 6z" />
            <path d="M0-.25h24v24H0z" fill="none" />
          </svg>
        </slot>
      </div>
    </div>
  </div>
</template>

<script>
import IndexedDbImg from "@/components/IndexedDbImg.vue";

export default {
  components: {
    IndexedDbImg,
  },
  props: {
    visible: Boolean,
    idbKeys: Array,
    index: Number,
  },
  emits: [
    'update:visible',
    'update:index'
  ],
  computed: {
    hasNext () {
      return ((this.index + 1) < this.idbKeys.length)
    },
    hasPrevious () {
      return ((this.index - 1) >= 0)
    },
    idbKey () {
      return this.idbKeys[this.index];
    },
  },
  methods: {
    previous () {
      if (this.hasPrevious) {
        this.$emit('update:index', this.index - 1);
      }
    },
    next () {
      if (this.hasNext) {
        this.$emit('update:index', this.index + 1);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.idb-lightbox {
  position: fixed;
  top: 56px;
  right: 0;
  bottom: 50px;
  left: 0;
  z-index: 10000;
  background: rgba(0, 0, 0, .8);

  * {
    user-select: none;
  }
}

.idb-lightbox__close {
  position: absolute;
  right: 0;
  top: 0;
  padding: 1rem;
  font-size: 1.5rem;
  cursor: pointer;
  color: #fff;
  width: 4rem;
  height: 4rem;
}

.idb-lightbox__arrow--invisible {
  visibility: hidden;
}

.idb-lightbox__element {
  display: flex;
  width: 100%;
  height: 100%;
}

.idb-lightbox__arrow {
  padding: 0 2rem;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
}

.idb-lightbox__arrow svg {
  fill: #fff;
  pointer-events: none;
}

.idb-lightbox__image {
  flex: 1;
  display: flex;
  align-content: center;
  justify-content: center;
}

.idb-lightbox__image img {
  display: block;
  width: auto;
  height: auto;
  max-width: 100%;
  max-height: 100%;
}
</style>
