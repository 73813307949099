<template>
  <section class="video-cap-container" v-if="isValid">
    <div class="stream-container">
      <video ref="videoRec" class="camera" autoplay playsinline muted  />
      <template v-if="!isFinished">
        <button v-if="!isRecording" @click="record" class="btn flex-center videobtn">Record</button>
        <button v-else @click="stop" class="videobtn" ref="stopBtn">
          Stop
        </button>
      </template>
    </div>
    <div class="controls" v-if="isFinished">
      <button type="button" class="videobtn" @click.prevent="resetVideo">Save</button>
      <button type="button" class="videobtn" @click.prevent="cancelVideo">Cancel</button>
    </div>
  </section>
</template>

<script>

export default {
  props: {
    question: {
      default: null
    }
  },
  emits: ["emitVideoData"],
  data () {
    return {
      errText: null,
      isValid: true,
      isRecording: false, // recording mode identifier
      isFinished: false, // finished recording - action buttons indicator
      recorder: null, // component wide MediaRecorder
      connection: null, // component wide WebSocket
      videoUrl: null, // link to video - assigned when done writing video file
      stream: null,
      containerType: "video/webm",
      chunks: [],
      soundMeter: null,
      maxLength: 10000,
      currentLength: 0
    };
  },
  methods: {
    // reset video display with media device media stream
    resetVideo () {
      this.isFinished = false;
      this.isRecording = false;
      this.$refs.videoRec.muted = true;
      navigator.mediaDevices
        .getUserMedia({
          audio: false,
          video: {
            width: {
              min: 640,
              ideal: 1280,
              max: 1280
            },
            height: {
              min: 480,
              ideal: 720,
              max: 720
            },
            framerate: 24,
            facingMode: "environment" ,
            aspectRatio: { exact: 1 }
          }
        })
        .then(this.gotStream)
        .catch(() => {
          this.isValid = false
        });
    },
    // start recoording
    async record () {
      this.$refs.videoRec.style.display = "block"
      await this.resetVideo();
      // this.recorder.start();
      this.isRecording = true;
    },
    // stop recording
    stop () {
      this.recorder.stop();

      this.stream.getTracks().forEach(function (track) {
        track.stop();
      });

      // this.stopVideo();
      this.$refs.videoRec.style.display = "none"
      this.isRecording = false;
      this.isFinished = true;
      this.currentLength = 0;
    },
    // reset video diaply and emit video file url
    done () {
      this.resetVideo();
    },
    // initialize MediaRecorder and video element source
    gotStream (mediaStream) {
      this.stream = mediaStream;

      var mimeType = "";
      if (typeof MediaRecorder.isTypeSupported == 'function') {
        if (MediaRecorder.isTypeSupported('video/webm;codecs=vp9')) {
          mimeType = 'video/webm;codecs=vp9';
        } else if (MediaRecorder.isTypeSupported('video/webm;codecs=h264')) {
          mimeType = 'video/webm;codecs=h264';
        } else if (MediaRecorder.isTypeSupported('video/webm')) {
          mimeType = 'video/webm';
        } else if (MediaRecorder.isTypeSupported('video/webm;codecs=vp8')) {
          mimeType = 'video/webm; codecs=vp8';
        } else if (MediaRecorder.isTypeSupported('video/mp4')) {
          mimeType = 'video/mp4';
          this.containerType = "video/mp4;codecs=avc1";
        }
        this.errText = mimeType

        this.recorder = new MediaRecorder(mediaStream, {
          mimeType: mimeType,
          audioBitsPerSecond: 0,
          videoBitsPerSecond: 1250000
        });
      } else {
        this.recorder = new MediaRecorder(mediaStream);
      }
      // this.recorder.setMaxDuration(10000)
      let that = this;

      this.recorder.ondataavailable = function (event) {
        that.currentLength = that.currentLength + 500;

        if (event.data && event.data.size > 0) {
          that.chunks.push(event.data)
        }

        if (that.currentLength >= that.maxLength) {
          const elem = that.$refs.stopBtn;
          if (elem != null) {
            elem.click();
          }

          that.stream.getTracks().forEach(function (track) {
            track.stop();
          });
        }
      }
      // this.recorder.onstop = this.stopVideo;
      this.recorder.onstop = function () {
        var blob = new Blob(that.chunks, {
          'type': that.containerType
        });
        that.chunks = [];
        let reader = new FileReader();

        reader.readAsDataURL(blob);
        reader.onloadend = () => {
          that.errText = reader.result.slice(0, 100)
          that.$emit('emitVideoData', reader.result, that.question)
        };
      }

      this.$refs.videoRec.src = null;
      this.$refs.videoRec.srcObject = mediaStream;
      this.$refs.videoRec.play();

      try {
        window.AudioContext = window.AudioContext || window.webkitAudioContext;
        window.audioContext = new AudioContext();
      } catch (e) {
        console.log('Web Audio API not supported.');
      }

      // this.toggleVideo();
      this.recorder.start(500);
    },
    cancelVideo () {
      this.$emit('emitVideoData', null, this.question)
      this.resetVideo()
    },
    saveVideo (question) {
      var video = question.answer.answer;
      if (video != null) {
        video = video.replace(",opus", "")
      }
      this.answersInput[question.id] = video

      this.$store.dispatch("auth/saveJob", this.job);
    },
    // toggle video display
    // toggleVideo() {
    //   this.$refs.videoRec.loop = !this.$refs.videoRec.loop;
    //   this.$refs.videoRec.controls = !this.$refs.videoRec.controls;
    // }
  },
};
</script>

<style scoped lang="scss">
.camera {
  width: 100%;
  display: none;
  margin-bottom: 20px;
}

.videobtn {
  background-color: #172B45;
  padding: 6px 10px;
  font-size: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-right: 10px;
}

.controls {
  display: flex;
  width: 100%;
  justify-content: flex-start;
}

video {
  width: 100%;
  margin-bottom: 20px;
}
</style>
