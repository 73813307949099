<template>
  <div class="aspect-ratio-outer" :style="`padding-bottom: ${paddingBottom}`">
    <div class="aspect-ratio-inner">
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  props: {
    aspectWidth: Number,
    aspectHeight: Number,
  },
  computed: {
    paddingBottom () {
      return (this.aspectHeight && this.aspectWidth)
        ? ((this.aspectHeight / this.aspectWidth) * 100) + "%"
        : 0;
    },
  },
};
</script>

<style lang="scss" scoped>
.aspect-ratio-outer {
  position: relative;
}

.aspect-ratio-inner {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
