// import store from '../store'
import localForage from 'localforage';

export default {
  freshObject (data) {
    let original = Object.assign({}, data);
    return JSON.stringify(original)
  },

  makeTitle (asset) {
    let title = asset.address;
    if (asset.address2 != null) {
      title += ", " + asset.address2;
    }
    if (asset.address3 != null) {
      title += ", " + asset.address3;
    }
    if (asset.city != null) {
      title += ", " + asset.city;
    }
    if (asset.postcode != null) {
      title += ", " + asset.postcode;
    }

    return title;
  },
  tidyJob (job) {
    delete job.asset;
    delete job.submitted;
    delete job.job_code;
    delete job.job_date;
    delete job.job_date_timestamp;
    delete job.progress;
    delete job.status;
    delete job.survey.created_by;
    delete job.survey.description;
    delete job.survey.name;
    delete job.survey.tags;
    delete job.survey.type;

    for (let section_prop in job.survey.sections) {
      let current = job.survey.sections[section_prop];

      if (current.id > 0) {
        delete current.name;
        delete current.description;
        delete current.icon;
        delete current.order;
        delete current.type;
        delete current.notes;
        delete current.tags;
        delete current.repeatable;
      }

      delete current.created_by;
      delete current.icon_import;
      delete current.progress;
      delete current.progress_calculated;

      for (let item_prop in current.items) {
        let current_item = current.items[item_prop];

        if (current_item.id > 0) {
          delete current_item.name;
          delete current_item.order;
          delete current_item.description;
          delete current_item.icon;
          delete current_item.type;
          delete current_item.notes;
          delete current_item.tags;
          delete current_item.repeatable;
        }
        delete current_item.icon_import;
        delete current_item.canSubmit;
        delete current_item.complete;
        delete current_item.created_by;
        delete current_item.recommended;
        delete current_item.required;

        for (let question_prop in current_item.questions) {
          let current_question = current_item.questions[question_prop];

          if (current_item.id > 0) {
            delete current_question.title;
            delete current_question.type;
            delete current_question.notes;
            delete current_question.required;
            delete current_question.description;
            delete current_question.created_by;
            delete current_question.tags;
            delete current_question.choices;
            delete current_question.icon;
            delete current_question.item_id;
            delete current_question.order;
          }
        }
      }
    }

    return job;
  },

  async lowestImageID () {
    return new Promise(function (resolve, reject) {
      localForage.keys().then(function (keys) {
        let min = 0;
        if (keys.length > 0) {
          keys = keys.filter(a => !isNaN(Number(a)));
          if (keys.length > 0) {
            min = keys.reduce((a, b) => Math.min(a, b))
          }
        }

        if (min < 0) {
          min = min - 1;
        } else {
          min = -1;
        }

        resolve(min.toString())
      }).catch(function () {
        reject(false);
      })
    })
  }
}
